<template>
    <UiAccordionItem :title="filter.label"
                     content-class="flex flex-col gap-2.5"
                     :expanded="expanded"
                     :aria-label="(isExpanded: boolean) => isExpanded ? $t('accessibility.collapse_filter_block', [filter.label]) : $t('accessibility.expand_filter_block', [filter.label])"
    >
        <a v-for="filterItem in filterItemsToDisplay"
           :key="filterItem.id!"
           :href="filterItem.url!"
           class="hidden"
        >
            {{ filterItem.label }}
        </a>

        <BaseUiFormCheckbox
            v-for="filterItem in filterItemsToDisplay"
            :key="filterItem.id!"
            :value="filterItem.isSelected!"
            label-class="flex items-center gap-3"
            @change="$emit('change', filterItem.url!)"
        >
            {{ filterItem.label }}
        </BaseUiFormCheckbox>
    </UiAccordionItem>
</template>

<script lang="ts" setup>

const {
    filter,
    expanded,
} = defineProps<{
    filter: InstanceType<typeof ProductFilterModel>
    expanded?: boolean
}>()

const emit = defineEmits<{
    change: [url: string]
}>()

const filterItemsToDisplay = computed(() => {
    if (!Array.isArray(filter.items)) return []

    return filter.items?.filter(item => item.shouldBeVisible()) ?? []
})

</script>

<style lang="scss" scoped>

</style>

<template>
    <UiButtonIcon
        class="mon-fav-btn"
        variant="plain"
        size="n-medium"
    >
        <IconHeart
            :width="22"
        />
    </UiButtonIcon>
</template>

<script lang="ts" setup>

</script>

<style lang="scss" scoped>

.mon-fav-btn:hover {
    color: $mon-c-danger-500;
}

</style>

export const PRODUCT_CARD_EMBEDS = [
    ProductModel.EMBED_COMPUTED_TAXED_PRICES,
    ProductModel.EMBED_DISCOUNT_PERCENTS,
    ProductModel.EMBED_DEFAULT_IMAGE_URL,
    ProductModel.EMBED_SECONDARY_IMAGE_URL,
    ProductModel.EMBED_URLS,
    ProductModel.EMBED_TAGS,
    ProductModel.EMBED_PRODUCT_AVAILABILITY,
    ProductModel.EMBED_ACTIVE_PRODUCT_VARIATIONS_COUNT,
    ProductModel.EMBED_PRODUCT_VARIATION_PROPERTIES,
    ProductModel.EMBED_DISCOUNTED_TAXED_PRICE,
    ProductModel.EMBED_CUSTOMER_PRODUCT_WISH_LIST_ITEM_ID,
    ProductModel.EMBED_CUSTOMER_PRODUCT_VARIATIONS_WISH_LIST_ITEMS,
] as const

export const PRODUCT_VARIATION_CARD_EMBEDS = [
    ProductVariationModel.EMBED_COMPUTED_TAXED_PRICES,
    ProductVariationModel.EMBED_PROPERTIES,
    ProductVariationModel.EMBED_PRODUCT_AVAILABILITY,
    ProductVariationModel.EMBED_DISCOUNT_PERCENTS,
] as const

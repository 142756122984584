<template>
    <div class="flex flex-col gap-6">
        <h1 class="mon-h1">
            {{ name }}
        </h1>

        <!--  TODO: come up with a more robust way of handling empty descriptions -->
        <div v-if="parsedDescription.length > 100" class="flex flex-col gap-4">
            <UiCollapseText :height="106" class="mon-wysiwyg-text mon-category-info__text xl:w-5/12">
                <span v-html="parsedDescription" />

                <template #button>
                    <UiLink :to="{ hash: `#${categoryDescriptionId}` }" size="xs" class="mt-1 w-fit">
                        {{ $t('labels.read_more') }}
                    </UiLink>
                </template>
            </UiCollapseText>
        </div>
    </div>
</template>

<script lang="ts" setup>

const {
    name,
    description,
    categoryDescriptionId,
} = defineProps<{
    name: string
    description: string
    categoryDescriptionId: string
}>()

const parsedDescription = computed(() => parseNewlinesIntoBr(description))

</script>

<style scoped lang="scss">

.mon-category-info__text {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

</style>

<!--  eslint-disable vue/multi-word-component-names  -->
<template>
    <div>
        <CoreContainer class="mon-collection" tag="div">
            <BaseContainerContent>
                <slot />
            </BaseContainerContent>

            <hr class="m-0">

            <BaseContainerContent
                class="mon-collection__content"
                :class="{
                    'mon-collection__content--unfilterable': filtersResponse?.getItem()?.isFilterPanelVisible(),
                }"
            >

                <!--  Controls  -->
                <div class="flex w-full items-center justify-between">
                    <!--  Filter buttons  -->
                    <div class="hidden items-center lg:flex">
                        <BaseUtilCollapseButton v-model="collapseData">
                            <template #leading>
                                <span
                                    class="mr-2 flex size-11 items-center justify-center rounded-full border border-solid border-black"
                                >
                                    <IconChevron size="xs" :up="collapseData.isExpanded" aria-hidden />
                                </span>
                            </template>
                            <span class="mon-text-base">
                                {{ collapseData.isExpanded ? $t('filters.hide') : $t('filters.show') }}
                            </span>
                        </BaseUtilCollapseButton>

                        <template v-if="selectedFiltersCount > 0">
                            <span class="mon-text-big mx-4" aria-hidden="true">
                                |
                            </span>

                            <BaseUiButton
                                variant="bare"
                                size="none"
                                @click="removeFilters"
                            >
                                <span class="mon-text-base">
                                    {{ `${$t('labels.remove')} (${selectedFiltersCount})` }}
                                </span>
                            </BaseUiButton>
                        </template>
                    </div>

                    <!--  Sort and per-page controls  -->
                    <template v-if="filtersResponse?.getItem()?.getProducts().length">
                        <div class="flex flex-wrap items-center justify-end gap-x-4 gap-y-8">
                            <!--  Mobile open filters button  -->
                            <UiButtonIcon class="lg:hidden" @click="toggleDrawer">
                                <IconSettings width="29" height="30" />
                            </UiButtonIcon>

                            <UiPaginationCount
                                v-if="filtersResponse?.getItem()?.paginationData"
                                t="filters.products_range"
                                :pagination="filtersResponse!.getItem()!.paginationData!"
                                @change="handlePerPageChange"
                            />

                            <FilterSortSelect
                                v-if="filtersResponse?.getItem()?.sortOptions"
                                :options="filtersResponse!.getItem()!.sortOptions!"
                                @change="handleSortChange"
                            />
                        </div>
                    </template>
                </div>

                <!--  Collection content  -->
                <div class="lg:flex">
                    <!--  FILTERS  -->
                    <BaseUtilCollapse
                        v-model="collapseData"
                        direction="horizontal"
                        class="mon-collection__filters hidden lg:block"
                    >
                        <FilterPanel
                            v-if="filtersResponse?.getItem()?.isFilterPanelVisible()"
                            :filters="filtersResponse!.getItem()!.getFilterBlocks() ?? []"
                            :sub-categories="subCategories ?? []"
                            :is-brand-category="isBrandCategory"
                            :loading="areFiltersLoading"
                            class="mon-collection__filters-content"
                            initially-expanded
                            @change="handleFiltersChange"
                        />
                    </BaseUtilCollapse>

                    <!--  PRODUCTS  -->
                    <div ref="productsListWrapperEl" class="mon-collection__products">
                        <template v-if="filtersResponse?.getItem()?.getProducts().length">
                            <LayoutProductList>
                                <ProductCard
                                    v-for="product in filtersResponse?.getItem()?.getProducts()"
                                    :key="product.id!"
                                    :product="product"
                                    :loading="areFiltersLoading || areProductsLoading"
                                />
                            </LayoutProductList>

                            <!--  Pagination  -->
                            <div class="xxl:grid mt-8 flex grid-cols-5 items-center justify-center gap-6 text-center lg:mt-28 lg:justify-between">
                                <BaseUiButton
                                    v-if="!filtersResponse?.getItem()?.paginationData?.hasReachedLastPage()"
                                    color="primary"
                                    class="xxl:mx-auto col-start-3"
                                    :on-click="handleShowMoreItems"
                                >
                                    <i18n-t keypath="filters.display_more" scope="global">
                                        <template #number>
                                            {{ filtersResponse?.getItem()?.paginationData?.perPage }}
                                        </template>
                                    </i18n-t>
                                </BaseUiButton>

                                <!--  PAGINATION  -->
                                <BaseUiPagination
                                    :aria-label="$t('accessibility.pagination_for_products')"
                                    :number-of-pages="filtersResponse?.getItem()?.paginationData?.pages"
                                    :current-page="filtersResponse?.getItem()?.paginationData?.page"
                                    class="ml-auto hidden lg:block"
                                    :class="{
                                        'col-span-2': !filtersResponse?.getItem()?.paginationData?.hasReachedLastPage(),
                                        'col-span-5': filtersResponse?.getItem()?.paginationData?.hasReachedLastPage(),
                                    }"
                                    @change="handlePaginationChange"
                                />
                            </div>
                        </template>

                        <NoResultsComponent
                            v-else
                            :title="$t('product.collection.empty')"
                            :subtitle="$t('search.products')"
                        />
                    </div>
                </div>
            </BaseContainerContent>
        </CoreContainer>

        <SectionSearch />

        <slot name="bottom" />

        <!-- ------------------------------------------------------- -->
        <!--  a floating panel on the left side of the page with filters  -->
        <BaseSideDrawer
            v-model="isDrawerOpen"
            auto-close-on="route-name-change"
            size="sm"
            @closed="isDrawerOpen = false"
        >
            <!--  HEADER  -->
            <template #header>
                <UiModalHeader title-class="flex items-start gap-1">
                    <BaseUiButton
                        v-if="selectedFiltersCount > 0"
                        variant="bare"
                        size="none"
                        class="py-2"
                        @click="removeFilters"
                    >
                        {{ `${$t('filters.remove')} (${selectedFiltersCount})` }}
                    </BaseUiButton>
                </UiModalHeader>
            </template>

            <FilterPanel
                :filters="filtersResponse?.getItem()?.getFilterBlocks() ?? []"
                :sub-categories="subCategories ?? []"
                :is-brand-category="isBrandCategory"
                :loading="areFiltersLoading"
                @change="handleFiltersChange"
            />
        </BaseSideDrawer>
    </div>
</template>

<script lang="ts" setup>
import type { ApiResponse } from '@composable-api/api.response'
import type { ProductFiltersResponseModel } from '@simploshop-models/product-filters-response.model'
import type { AsyncData } from '#app'

const {
    isBrandCategory,
    subCategories,
    filtersResponse,
    refreshFilters,
} = defineProps<{
    isBrandCategory?: boolean
    subCategories: InstanceType<typeof CategoryModel>[]
    filtersResponse: ApiResponse<ProductFiltersResponseModel> | undefined
    refreshFilters: AsyncData<any, any>['refresh']
}>()

const route = useRoute()
const router = useRouter()
const { t } = useI18n()

const modelValue = defineModel<string>()

// re-fetch products on navigation in history
watch(() => route.path, (newVal) => {
    if (newVal === modelValue.value) return
    handleFiltersChange(newVal)
})

const selectedFiltersCount = computed(() => {
    return filtersResponse?.getItem()?.getSelectedFilters().length ?? 0
})

const { collapseData } = useCollapse({
    defaultExpanded: true,
})

const isDrawerOpen = ref<boolean>(false)

const isOverLgBreakpoint = useScssBreakpoints().greaterOrEqual('lg')
watch(isOverLgBreakpoint, (isLg) => {
    if (!isLg) return
    isDrawerOpen.value = false
})

function toggleDrawer() {
    isDrawerOpen.value = !isDrawerOpen.value
}

const productsListWrapperEl = ref<HTMLElement | null>(null)

const areFiltersLoading = ref<boolean>(false)

async function handleFiltersChange(url: string) {
    areFiltersLoading.value = true
    await _applyFilters(url)
    areFiltersLoading.value = false
}

async function handleSortChange(url: string) {
    areProductsLoading.value = true
    await _applyFilters(url)
    areProductsLoading.value = false
}

async function handlePerPageChange(url: string) {
    areProductsLoading.value = true
    await _applyFilters(url)
    areProductsLoading.value = false
}

/**
 * Sets the new url to filter the products with.
 * Also updates the url in the browser.
 *
 * !! THIS METHOD IS NOT MEANT TO BE CALLED FROM THE UI !!
 *
 * @param url the new url to filter with
 */
async function _applyFilters(url: string) {
    modelValue.value = url
    await refreshFilters()
    // redirect to the URI from BE if available (to hide `?page=1`, for example)
    await router.replace(filtersResponse?.getItem()?.requestUri ?? url)
}

const areProductsLoading = ref<boolean>(false)

async function handlePaginationChange(newPage: number) {
    const newUrl = filtersResponse?.getItem()?.paginationData?.getPageUrl(newPage)
    if (!newUrl) return
    areProductsLoading.value = true
    await _applyFilters(newUrl)
    areProductsLoading.value = false
    productsListWrapperEl.value?.scrollIntoView({ behavior: 'smooth' })
}


// FETCH MORE ITEMS
async function handleShowMoreItems() {
    const newUrl = filtersResponse?.getItem()?.paginationData?.getNextPageRangeUrl()
    if (!newUrl) return
    await _applyFilters(newUrl)
}

async function removeFilters() {
    areProductsLoading.value = true
    modelValue.value = route.path
    await refreshFilters()
    // redirect to the URI from BE if available (to hide `?page=1`, for example)
    await router.replace(filtersResponse?.getItem()?.requestUri ?? route.path)
    areProductsLoading.value = false
}

</script>

<style lang="scss" scoped>
@use "sass:math";

$col-gap: 3.375rem;
$desktop-filter-width: 15rem;
$big-desktop-filter-width: 22rem;

.mon-collection__content {
    display: flex;
    flex-direction: column;
    gap: 3rem;

    @include more-than(lg) {
        gap: 1.625rem;
    }
}

.mon-collection__filters {
    margin-bottom: auto;
    white-space: nowrap;

    @include more-than(lg) {
        width: 100%;
        max-width: calc($desktop-filter-width + $col-gap);
    }

    @include more-than-custom(1920) {
        max-width: calc($big-desktop-filter-width + $col-gap);
    }
}

.mon-collection__filters-content {
    @include more-than(lg) {
        min-width: $desktop-filter-width;
        padding-right: $col-gap;
    }

    @include more-than-custom(1920) {
        min-width: $big-desktop-filter-width;
    }
}

.mon-collection__products {
    // TODO: replace with actual header height
    scroll-margin: 17rem;
    flex: 1;
}

</style>

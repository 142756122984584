<script lang="tsx">
import { defineComponentBaseUtilCollapse } from '@core/app/components/base/util/BaseUtilCollapse.vue'

export default defineComponentBaseUtilCollapse()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUtilCollapse" as *;

//
@include set-duration($mon-trans-time-long);
@include set-timing($mon-timing);

</style>

<template>
    <div class="mon-product-card">

        <!--  IMAGE BOX  -->
        <UiCard class="mon-product-card__img-box"
                :color="!loading ? 'primary' : undefined"
                size="none"
        >
            <BaseUiSkeleton v-if="loading" class="mon-product-card__img-skeleton" />

            <template v-else>
                <NuxtLink :to="product?.getUrl() ?? ''" class="mon-product-card__link absolute inset-0 z-20" />

                <!--  TOP INFO  -->
                <div class="sim-product-card__info sim-product-card__info--top">
                    <div class="flex gap-2">
                        <!--  SALE  -->
                        <UiBadgeAutoColor
                            v-if="product?.isDiscounted()"
                            :label="$t('tags.sale')"
                        />

                        <!--  NEW  -->
                        <UiBadgeAutoColor
                            v-if="product?.tags?.find(tag => tag.label === $t('tags.new'))"
                            :label="$t('tags.new')"
                        />

                        <!--  PACKAGE  -->
                        <UiBadgeAutoColor
                            v-if="product?.tags?.find(tag => tag.label === $t('tags.package'))"
                            :label="$t('tags.package')"
                        />
                    </div>

                    <!--  TODO: implement product favorites  -->
                    <ProductFavoriteButton class="z-30" />
                </div>

                <!-- IMAGE  -->
                <div class="mon-product-card__image">
                    <CoreImg
                        class="absolute inset-0 size-full object-contain"
                        :src="product?.getHeroImage()"
                        :alt="product?.name"
                        :width="205"
                        :placeholder="[28*2, 28*2, 100, 4]"
                        sizes="50vw sm:50vw lg:400px xl:400px"
                        loading="lazy"
                    />
                </div>

                <!--  BOTTOM INFO  -->
                <div class="sim-product-card__info sim-product-card__info--bottom z-10">
                    <!--  BRAND  -->
                    <!-- TODO: replace with brand -->
                    <BaseUiBadge
                        color="basic"
                        size="sm"
                    >
                        DEPOT
                    </BaseUiBadge>
                </div>
            </template>
        </UiCard> <!--  end of IMAGE BOX  -->


        <!--  CONTENT  -->
        <div class="mon-product-card__content">
            <BaseUiSkeleton v-if="loading" class="h-4 w-2/3 shrink-0 max-xl:mb-1 xl:h-5" />
            <div v-else class="mon-product-card__title">
                {{ product?.name }}
            </div>

            <div class="flex size-full justify-between gap-2">
                <div class="flex w-full flex-col justify-between gap-2.5">
                    <BaseUiSkeleton v-if="loading" class="h-4 w-3/4 xl:my-1 xl:h-5" />
                    <p v-else class="mon-product-card__description">
                        {{ sanitizeText(product?.shortDescription) }}
                    </p>

                    <BaseUiSkeleton v-if="loading" class="h-5 w-1/4" />
                    <ProductPrice v-else :product="product" />
                </div>

                <div class="mon-product-card__actions z-30">
                    <BaseUiSkeleton v-if="loading" class="size-15 rounded-full max-lg:hidden" />

                    <!-- TODO: replace is-added with real value -->
                    <ProductAddToCartButton
                        v-else
                        :is-added="false"
                        variant="solid-shadow"
                        @click="addItemToCart"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>

const {
    product = null,
    loading,
} = defineProps<{
    product?: InstanceType<typeof ProductModel> | null
    loading?: boolean
}>()


const { addToCart } = useCart()

async function addItemToCart() {
    if (!product?.id) return

    await addToCart({
        product_id: product.id,
        amount: 1,
    })
}

</script>

<style lang="scss" scoped>
$info-height: 2.75rem;
$image-box-content-padding: 0.625rem;
$product-card-gap: 0.75rem;

.mon-product-card {
    display: flex;
    flex-direction: column;
    gap: $product-card-gap;
    position: relative;
}

.mon-product-card__img-box {
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    flex-shrink: 0;
}

.mon-product-card__image {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    flex-grow: 1;
    flex-shrink: 0;

    aspect-ratio: 1/1;

    overflow: hidden;

    & > img {
        transition-duration: $mon-trans-time-extra-long;
    }
}

.mon-product-card__link:hover ~ .mon-product-card__image > img {
    transform: scale(1.05);
    transition: transform $mon-trans-time-long $mon-timing;
}

.mon-product-card__content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.25rem;
    height: 100%;
}

.mon-product-card__title {
    @include mon-text-extra-small;
    @include mon-font-bold;

    line-height: 1.25rem;
    min-height: 1.25rem;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mon-product-card__description {
    font-size: 0.8125rem;
    line-height: 1.25rem;
}

.mon-product-card__actions {
    @include less-than(lg) {
        position: absolute;
        right: $image-box-content-padding;
        top: calc(-1 * (#{$product-card-gap} + #{$image-box-content-padding}));
        transform: translateY(-100%);
    }

    @include more-than(lg) {
        margin-top: auto;
    }
}

.sim-product-card__info {
    display: flex;
    justify-content: space-between;
    align-items: center;

    height: $info-height;
    box-sizing: border-box;

    padding: $image-box-content-padding;
}

.sim-product-card__info--bottom {
    @include less-than(lg) {
        // 48px = icon button size
        max-width: calc(100% - 48px - #{$image-box-content-padding});
    }
}

.mon-product-card__img-skeleton {
    aspect-ratio: 1/1;
    padding-top: calc(2 * #{$info-height});
}

</style>

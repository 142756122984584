<template>
    <div class="mon-product-list">
        <slot />
    </div>
</template>

<script lang="ts" setup>

</script>

<style lang="scss" scoped>

.mon-product-list {
    display: grid;
    gap: 1.875rem 0.625rem;
    grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));

    @include more-than(sm) {
        grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
    }

    @include more-than(lg) {
        grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
    }

    @include more-than(xxl) {
        grid-template-columns: repeat(auto-fill, minmax(17rem, 1fr));
        gap: 2.5rem 1.25rem;
    }
}

</style>

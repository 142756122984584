<script lang="tsx">
import { defineComponentBaseUiRange } from '@core/app/components/base/ui/BaseUiRange.vue'

export default defineComponentBaseUiRange()

</script>

<style lang="scss" module>
@use "@core-scss/components/BaseUiRange" as *;

@include wrapper {
    height: 0.0625rem;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        inset: -0.5rem 0;
        pointer-events: auto;
    }
}

@include set-track-background-color($mon-c-line);
@include set-track-range-color($mon-c-black);

@include track {
    border-radius: 100vmax;
}

@include thumb {
    width: 0.6875rem;
    height: 0.6875rem;

    background-color: $mon-c-black;
    border: 1px $mon-c-black solid;
    border-radius: 100vmax;
}

</style>

<template>
    <div class="mon-accordion"
         :class="{
             'mon-accordion--expand': expandInContainer,
         }"
    >
        <!--  Label Button  -->
        <button
            class="mon-accordion__label mon-border-bottom"
            :class="{
                'mon-border-top': borderTop,
            }"
            type="button"
            :aria-label="ariaLabel ? ariaLabel(isExpanded) : isExpanded ? $t('accessibility.collapse_accordion_for', [title]) : $t('accessibility.expand_accordion_for', [title])"
            :aria-expanded="isExpanded"
            :aria-controls="contentId"
            @click="toggleExpanded"
        >
            <template v-if="!expandInContainer">
                <slot name="title">
                    {{ title }}
                </slot>
                <IconChevron
                    :up="isExpanded"
                    size="xs"
                    class="mon-c-black"
                    aria-hidden
                />
            </template>
            <span v-else class="mon-accordion__label-content">
                <slot name="title">
                    {{ title }}
                </slot>
                <IconChevron
                    :up="isExpanded"
                    size="xs"
                    class="mon-c-black"
                    aria-hidden
                />
            </span>
        </button>

        <!--  Content (collapsible)  -->
        <BaseUtilCollapse v-model="isExpanded" :container-id="contentId">
            <div v-if="!lazyMount || wasExpanded" class="mon-accordion__content" :class="contentClass">
                <slot :is-expanded="isExpanded" />
            </div>
        </BaseUtilCollapse>
    </div>
</template>

<script lang="ts" setup>

const {
    title,
    expanded,
    contentClass,
    ariaLabel,
    expandInContainer,
    borderTop,
} = defineProps<{
    title?: string | null | undefined
    expanded?: boolean
    contentClass?: string
    ariaLabel?: (isExpanded: boolean) => string
    /**
     * If enabled, the button will inherit negative margin based on the BaseContainerContent's inline padding
     * and the content will remain visually unchanged.
     *
     * This can be useful to make the border as wide as the container while keeping the content
     * visually aligned.
     */
    expandInContainer?: boolean
    borderTop?: boolean
    /**
     * Whether to mount the content only after it has been expanded for the first time.
     */
    lazyMount?: boolean
}>()

const contentId = useId()

const { is: isExpanded, was: wasExpanded } = useLazyMount(expanded)

function toggleExpanded() {
    isExpanded.value = !isExpanded.value
}

</script>

<style lang="scss" scoped>

.mon-accordion--expand {
    margin-left: calc(-1 * var(--pl, 0));
    margin-right: calc(-1 * var(--pr, 0));
}

.mon-accordion__label {
    @include mon-text-big;

    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 1rem 0;
}

.mon-accordion__label-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-left: var(--pl, 0);
    padding-right: var(--pr, 0);
}

.mon-accordion__content {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.mon-accordion--expand .mon-accordion__content {
    padding-left: var(--pl, 0);
    padding-right: var(--pr, 0);
}

</style>

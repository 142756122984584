<template>
    <div class="mon-collapse__text">
        <BaseUtilCollapse
            v-model="isOpen"
            :expanded-size="height"
            :expanded-breakpoint="expandedBreakpoint"
        >
            <slot />
        </BaseUtilCollapse>

        <div class="mon-collapse__button"
             :class="{
                 'mon-collapse__button--active': isOpen,
                 [{
                     xs: 'xs:hidden',
                     sm: 'sm:hidden',
                     md: 'md:hidden',
                     lg: 'lg:hidden',
                     xl: 'xl:hidden',
                     xxl: 'xxl:hidden',
                 }[expandedBreakpoint!]]: expandedBreakpoint,
             }"
        >
            <slot name="button">
                <BaseUiButton
                    content-class="mon-hover-link"
                    variant="bare"
                    size="none"
                    @click="isOpen = !isOpen"
                >
                    {{ isOpen ? $t('labels.show_less') : $t('labels.read_more') }}
                    <template #trailing>
                        <IconChevron :left="!isOpen" :up="isOpen" size="xs" />
                    </template>
                </BaseUiButton>
            </slot>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { ScssBreakpoint } from '@core/types/scss'

const {
    expandedBreakpoint,
    height = 64,
} = defineProps<{
    expandedBreakpoint?: ScssBreakpoint
    height?: number
}>()

const isOpen = ref<boolean>(false)

</script>

<style lang="scss" scoped>
.mon-collapse__button {
    position: relative;
    background-color: white;
    padding-top: 0.5rem;

    @include mon-text-base;

    &::before {
        content: '';
        position: absolute;
        inset: 0;
        height: 200%;
        transform: translateY(-100%);
        background: linear-gradient(rgb(255 255 255 / 0) 0%, #ffffff 100%);
        pointer-events: none;

        transition: opacity $mon-trans-time-normal $mon-timing;
    }
}

.mon-collapse__button--active {
    &::before {
        opacity: 0;
    }
}

</style>

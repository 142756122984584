<template>
    <div :class="{ 'mon-filters--loading': loading }">
        <FilterBlockSubcategories
            v-if="subCategories?.length"
            :sub-categories="subCategories"
            :expanded="initiallyExpanded"
            :title="isBrandCategory ? $t('pages.category.product_lines') : $t('pages.category.category')"
        />

        <div v-for="filter in filters"
             :key="filter.id!"
        >
            <FilterBlockRange
                v-if="filter.isRangeType()"
                :filter="filter"
                :expanded="!!filter.containsActiveItems || initiallyExpanded"
                :loading="loading"
                @change="handleFilterChange"
            />

            <FilterBlockCheckbox
                v-if="filter.isCheckboxType()"
                :filter="filter"
                :expanded="!!filter.containsActiveItems || initiallyExpanded"
                @change="handleFilterChange"
            />
        </div>
    </div>
</template>

<script lang="ts" setup>

const {
    filters,
    subCategories,
    isBrandCategory = false,
    loading,
    initiallyExpanded,
} = defineProps<{
    filters: InstanceType<typeof ProductFilterModel>[]
    subCategories?: InstanceType<typeof CategoryModel>[]
    isBrandCategory?: boolean
    loading: boolean
    initiallyExpanded?: boolean
}>()

const emit = defineEmits<{
    change: [url: string]
}>()
function handleFilterChange(url: string) {
    emit('change', url)
}

</script>

<style lang="scss" scoped>

.mon-filters--loading {
    opacity: 0.5;
    cursor: wait;

    & * {
        pointer-events: none;
    }
}

</style>

<!--  eslint-disable vue/multi-word-component-names  -->
<template>
    <LayoutProductCollectionDetail
        v-model="filterSlug"
        :filters-response="productFiltersResponse"
        :refresh-filters="refreshProductFilters"
        :sub-categories="subCategories"
        :is-brand-category="isBrandCategory"
    >
        <!--  CATEGORY INFO  -->
        <!--  includes the title and description  -->
        <CategoryInfo v-if="!isBrandCategory"
                      :name="category?.name!"
                      :description="category?.description ?? ''"
                      :category-description-id="CATEGORY_DESCRIPTION_ID"
        />

        <template v-if="category && isBrandCategory">
            <!-- TODO: banner -->
            <!--            <CategoryBrandBanner :banner="undefined" />-->

            <!-- TODO: product lines -->
            <!--            <CategoryProductLines-->
            <!--                v-if="subCategories?.length"-->
            <!--                :category-name="category.name!"-->
            <!--                :sub-categories="subCategories"-->
            <!--            />-->
        </template>

        <template #bottom>
            <CoreContainer v-if="category?.description?.length > 100 && !isBrandCategory">
                <BaseContainerContent>
                    <div
                        :id="CATEGORY_DESCRIPTION_ID"
                        class="mx-auto grid w-11/12 grid-cols-1 gap-5 lg:grid-cols-[30%_1fr]"
                    >
                        <h3 class="mon-h3">
                            {{ category?.name }}
                        </h3>

                        <div class="mon-wysiwyg-text" v-html="category?.description" />
                    </div>
                </BaseContainerContent>
            </CoreContainer>

            <div v-else>
                <!-- TODO: category description with image -->
            </div>
        </template>
    </LayoutProductCollectionDetail>
</template>

<script lang="ts" setup>
import { PRODUCT_CARD_EMBEDS } from '../assets/ts/constants/embeds'

const { page } = useCurrentPage()
const { t } = useI18n()
const CATEGORY_DESCRIPTION_ID = t('pages.category.description_id')

const runtimeConfig = useRuntimeConfig()
const route = useRoute()
const filterSlug = ref<string>(route.fullPath)

const [
    { item: category },
    { items: subCategories },
    {
        data: productFiltersResponse,
        refresh: refreshProductFilters,
    },
] = await Promise.all([
    // Category
    useCategoriesApiService()
        .onlyAttrs([
            CategoryModel.ATTR_ID,
            CategoryModel.ATTR_NAME,
            CategoryModel.ATTR_DESCRIPTION,
            CategoryModel.ATTR_PARENT_ID,
        ])
        .embed([
            CategoryModel.EMBED_THUMBNAIL_IMAGE_URL,
            CategoryModel.EMBED_IMAGE_URL,
        ])
        .forId(page.value!.model!.id)
        .useGet(),
    // Sub-Categories
    useCategoriesApiService()
        .whereEquals(CategoryModel.ATTR_PARENT_ID, page.value!.model!.id)
        .onlyAttrs([
            CategoryModel.ATTR_ID,
            CategoryModel.ATTR_NAME,
            CategoryModel.ATTR_DESCRIPTION,
        ])
        .embed([
            CategoryModel.EMBED_THUMBNAIL_IMAGE_URL,
            CategoryModel.EMBED_IMAGE_URL,
            CategoryModel.EMBED_URLS,
        ])
        .useGet(),
    // Products
    useProductsFilterApiService()
        .embed([
            ...PRODUCT_CARD_EMBEDS,
        ])
        .usePost({ request_uri: filterSlug }, { watch: false }),
])

const isBrandCategory = computed(() => category.value?.parentId === Number(runtimeConfig.public.id.categoryBrands))

</script>

<style lang="scss" scoped>

</style>

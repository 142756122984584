<script lang="tsx">
import { defineComponentBaseUiSkeleton } from '@core/app/components/base/ui/BaseUiSkeleton.vue'

type Colors = 'white' | 'primary'

export default defineComponentBaseUiSkeleton<Colors>({
    props: {
        color: {
            default: 'primary',
        },
    },
})

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiSkeleton" as *;

@include skeleton--color('white') {
    @include set-skeleton-color($mon-c-white);
    @include set-skeleton-shine-color($mon-c-primary-100);
}

@include skeleton--color('primary') {
    @include set-skeleton-color($mon-c-primary-200);
    @include set-skeleton-shine-color($mon-c-primary-100);
}

</style>

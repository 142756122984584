<template>
    <CoreContainer class="top-bottom-border">
        <BaseContainerContent
            class="flex flex-col items-center gap-8"
        >
            <h2 class="mon-subtitle -mt-0.5">
                {{ $t('search.not_found') }}
            </h2>

            <!--  INPUT  -->
            <div class="w-full max-w-2xl">
                <MainSearchComponent />
            </div>
        </BaseContainerContent>
    </CoreContainer>
</template>

<script lang="ts" setup>

</script>

<style lang="scss" scoped>

</style>

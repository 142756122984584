<script lang="tsx">
import { defineComponentBaseUiPagination } from '@core/app/components/base/ui/BaseUiPagination.vue'
import { IconChevron } from '#components'

export default defineComponentBaseUiPagination({
    props: {
        hideJumpButtons: {
            default: true,
        },
        hideDisabledNavigationButtons: {
            default: true,
        },
        hideWhenSinglePage: {
            default: true,
        },
        alignEnd: {
            default: true,
        },
        siblingCount: {
            default: 0,
        },
    },
    slots: {
        previousPageButton: () => <IconChevron size="xs" rotate={90} />,
        nextPageButton: () => <IconChevron size="xs" rotate={-90} />,
    },
})

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiPagination" as *;

$size: 3rem;

@include button {
    color: $mon-c-black;
    border-radius: $mon-border-radius;
    border: 1px solid $mon-c-primary-200;

    width: $size;
    height: $size;

    transition: background-color $mon-trans-time-normal $mon-timing, color $mon-trans-time-normal $mon-timing;

    @include mon-text-base;
    @include accessibility-focus-outline-offset;
}

@include button--hover {
    background-color: $mon-c-primary-100;
    transition-duration: $mon-trans-time-short;
}

@include button--active {
    background-color: $mon-c-primary-100;
    color: $mon-c-black;
}

@include button--active-hover {
    background-color: $mon-c-primary-100;
}

</style>
